import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';

//Component Import
import convertHtml from '../../utils/convertHtml';
import { keyframes } from '@emotion/core';

const TitleUnderlinedContainer = styled(motion.div)`
  font-size: ${props => props.fontSize};
  font-weight: 800;
  color: ${props => props.textColor};
  width: fit-content;
  padding-bottom: 0px;
  font-style: normal;
  font-family: Montserrat;
  text-align: ${props => props.align};
  line-height: ${props => props.lineHeight};
  @media (max-width: 965px) {
    font-size: ${props => props.tabletFontSize};
    line-height: ${props => props.tableLineHeight};
  }
  @media (max-width: 735px) {
    font-size: ${props => props.mobileFontSize};
    line-height: ${props => props.mobileLineHeight};
  }

  @media screen and (min-width: 320px) and (max-width: 800px) {
    margin: ${props => props.margin};
  }
`;

const TitleUnderlinedContainerAnim = styled(motion.div)`
  font-size: ${props => props.fontSize};
  font-weight: 800;
  font-style: normal;
  color: #fff;
  width: 100%;
  font-family: Montserrat;
  padding-bottom: 0px;
  text-align: ${props => props.align};
  line-height: ${props => props.lineHeight};
  @media (max-width: 965px) {
    font-size: ${props => props.tabletFontSize};
    line-height: ${props => props.tableLineHeight};
  }
  @media (max-width: 735px) {
    font-size: ${props => props.mobileFontSize};
    line-height: ${props => props.mobileLineHeight};
  }
  span {
    width: calc(100%);
    background-image: linear-gradient(
      transparent calc(100% - (${props => props.fontSize}) / 3.5),
      ${props => props.underlineColor} 10px
    );
    background-repeat: no-repeat;
    animation-name: slidein;
    animation-duration: 0.6s;

    animation-fill-mode: forwards;
    animation-delay: 0.4s;
    background-size: 0% 100%;
    @media (max-width: 965px) {
      background-image: linear-gradient(
        transparent calc(100% - (${props => props.tabletFontSize}) / 3.5),
        ${props => props.underlineColor} 10px
      );
    }
    @media (max-width: 735px) {
      background-image: linear-gradient(
        transparent calc(100% - (${props => props.mobileFontSize}) / 3.5),
        ${props => props.underlineColor} 10px
      );
    }
  }

  @media screen and (min-width: 320px) and (max-width: 800px) {
    margin: ${props => props.margin};
  }

  @keyframes slidein {
    from {
      background-size: 0% 100%;
    }

    to {
      background-size: 100% 100%;
    }
  }
`;

const TitleUnderlinedH2Container = styled(motion.h2)`
  font-size: ${props => props.fontSize};
  font-weight: 800;
  color: #fff;
  width: fit-content;
  padding-bottom: 0px;
  font-style: normal;
  font-family: Montserrat;
  text-align: ${props => props.align};
  line-height: ${props => props.lineHeight};
  @media (max-width: 965px) {
    font-size: ${props => props.tabletFontSize};
    line-height: ${props => props.tableLineHeight};
  }
  @media (max-width: 735px) {
    font-size: ${props => props.mobileFontSize};
    line-height: ${props => props.mobileLineHeight};
  }

  @media screen and (min-width: 320px) and (max-width: 800px) {
    margin: ${props => props.margin};
  }
`;

const TitleUnderlinedH2ContainerAnim = styled(motion.h2)`
  font-size: ${props => props.fontSize};
  font-weight: 800;
  color: #fff;
  width: fit-content;
  padding-bottom: 0px;
  font-style: normal;
  font-family: Montserrat;
  text-align: ${props => props.align};
  line-height: ${props => props.lineHeight};
  @media (max-width: 965px) {
    font-size: ${props => props.tabletFontSize};
    line-height: ${props => props.tableLineHeight};
  }
  @media (max-width: 735px) {
    font-size: ${props => props.mobileFontSize};
    line-height: ${props => props.mobileLineHeight};
  }
  span {
    width: calc(100%);
    background-image: linear-gradient(
      transparent calc(100% - (${props => props.fontSize}) / 3.5),
      ${props => props.underlineColor} 10px
    );
    background-repeat: no-repeat;
    animation-name: slidein;
    animation-duration: 0.6s;

    animation-fill-mode: forwards;
    animation-delay: 0.4s;
    background-size: 0% 100%;
    @media (max-width: 965px) {
      background-image: linear-gradient(
        transparent calc(100% - (${props => props.tabletFontSize}) / 3.5),
        ${props => props.underlineColor} 10px
      );
    }
    @media (max-width: 735px) {
      background-image: linear-gradient(
        transparent calc(100% - (${props => props.mobileFontSize}) / 3.5),
        ${props => props.underlineColor} 10px
      );
    }
  }

  @media screen and (min-width: 320px) and (max-width: 800px) {
    margin: ${props => props.margin};
  }
  @keyframes slidein {
    from {
      background-size: 0% 100%;
    }

    to {
      background-size: 100% 100%;
    }
  }
`;

const TitleUnderlinedH1Container = styled(motion.h1)`
  font-size: ${props => props.fontSize};
  font-weight: 800;
  font-style: normal;
  color: ${props => props.textColor};
  width: fit-content;
  font-family: Montserrat;
  padding-bottom: 0px;
  margin: 0;
  line-height: ${props => props.lineHeight};
  text-align: ${props => props.align};
  @media (max-width: 965px) {
    font-size: ${props => props.tabletFontSize};
    line-height: ${props => props.tableLineHeight};
  }
  @media (max-width: 735px) {
    font-size: ${props => props.mobileFontSize};
    line-height: ${props => props.mobileLineHeight};
  }
`;

const TitleUnderlinedH1ContainerAnim = styled(motion.h1)`
  font-size: ${props => props.fontSize};
  font-weight: 800;
  color: #fff;
  width: fit-content;
  padding-bottom: 0px;
  font-style: normal;
  font-family: Montserrat;
  margin: 0;
  line-height: ${props => props.lineHeight};
  text-align: ${props => props.align};
  @media (max-width: 965px) {
    font-size: ${props => props.tabletFontSize};
    line-height: ${props => props.tableLineHeight};
  }
  @media (max-width: 735px) {
    font-size: ${props => props.mobileFontSize};
    line-height: ${props => props.mobileLineHeight};
  }
  span {
    width: calc(100%);
    background-image: linear-gradient(
      transparent calc(100% - (${props => props.fontSize}) / 3.5),
      ${props => props.underlineColor} 10px
    );
    background-repeat: no-repeat;
    animation-name: slidein;
    animation-duration: 0.6s;

    animation-delay: 0.4s;
    animation-fill-mode: forwards;
    background-size: 0% 100%;
    @media (max-width: 965px) {
      background-image: linear-gradient(
        transparent calc(100% - (${props => props.tabletFontSize}) / 3.5),
        ${props => props.underlineColor} 10px
      );
    }
    @media (max-width: 735px) {
      background-image: linear-gradient(
        transparent calc(100% - (${props => props.mobileFontSize}) / 3.5),
        ${props => props.underlineColor} 10px
      );
    }
  }
  @keyframes slidein {
    from {
      background-size: 0% 100%;
    }

    to {
      background-size: 100% 100%;
    }
  }
`;

const animations = {
  hidden: {
    backgroundSize: '0%, 100%',
  },
  visible: {
    backgroundSize: '100%, 100%',
    transition: {
      delay: 0.75,
      duration: 0.5,
    },
  },
};

const animation = true;

export class TitleUnderlined extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <React.Fragment>
          {this.props.h1Mode && this.props.h1Mode ? (
            <React.Fragment>
              {this.state.visible ? (
                <TitleUnderlinedH1ContainerAnim
                  underlineColor={this.props.underlineColor}
                  textColor={this.props.textColor}
                  fontSize={this.props.fontSize}
                  lineHeight={this.props.lineHeight}
                  dangerouslySetInnerHTML={convertHtml(this.props.titleText)}
                  mobileFontSize={this.props.mobileFontSize}
                  mobileLineHeight={this.props.mobileLineHeight}
                  tabletFontSize={this.props.tabletFontSize}
                  tableLineHeight={this.props.tableLineHeight}
                  animate={this.state.visible ? 'visible' : 'hidden'}
                  variants={animations}
                  initial="hidden"
                  align={this.props.leftAligned ? 'left' : 'center'}
                />
              ) : (
                <TitleUnderlinedH1Container
                  underlineColor={this.props.underlineColor}
                  textColor={this.props.textColor}
                  fontSize={this.props.fontSize}
                  lineHeight={this.props.lineHeight}
                  dangerouslySetInnerHTML={convertHtml(this.props.titleText)}
                  mobileFontSize={this.props.mobileFontSize}
                  mobileLineHeight={this.props.mobileLineHeight}
                  tabletFontSize={this.props.tabletFontSize}
                  tableLineHeight={this.props.tableLineHeight}
                  animate={this.state.visible ? 'visible' : 'hidden'}
                  variants={animations}
                  initial="hidden"
                  align={this.props.leftAligned ? 'left' : 'center'}
                />
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {this.props.h2Mode && this.props.h2Mode ? (
                <React.Fragment>
                  {this.state.visible ? (
                    <TitleUnderlinedH2ContainerAnim
                      underlineColor={this.props.underlineColor}
                      textColor={this.props.textColor}
                      fontSize={this.props.fontSize}
                      lineHeight={this.props.lineHeight}
                      dangerouslySetInnerHTML={convertHtml(
                        this.props.titleText
                      )}
                      mobileFontSize={this.props.mobileFontSize}
                      mobileLineHeight={this.props.mobileLineHeight}
                      tabletFontSize={this.props.tabletFontSize}
                      tableLineHeight={this.props.tableLineHeight}
                      animate={this.state.visible ? 'visible' : 'hidden'}
                      variants={animations}
                      initial="hidden"
                      align={this.props.leftAligned ? 'left' : 'center'}
                    />
                  ) : (
                    <TitleUnderlinedH2Container
                      underlineColor={this.props.underlineColor}
                      textColor={this.props.textColor}
                      fontSize={this.props.fontSize}
                      lineHeight={this.props.lineHeight}
                      dangerouslySetInnerHTML={convertHtml(
                        this.props.titleText
                      )}
                      mobileFontSize={this.props.mobileFontSize}
                      mobileLineHeight={this.props.mobileLineHeight}
                      tabletFontSize={this.props.tabletFontSize}
                      tableLineHeight={this.props.tableLineHeight}
                      animate={this.state.visible ? 'visible' : 'hidden'}
                      variants={animations}
                      initial="hidden"
                      align={this.props.leftAligned ? 'left' : 'center'}
                    />
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.state.visible ? (
                    <TitleUnderlinedContainerAnim
                      underlineColor={this.props.underlineColor}
                      textColor={this.props.textColor}
                      fontSize={this.props.fontSize}
                      lineHeight={this.props.lineHeight}
                      dangerouslySetInnerHTML={convertHtml(
                        this.props.titleText
                      )}
                      mobileFontSize={this.props.mobileFontSize}
                      mobileLineHeight={this.props.mobileLineHeight}
                      tabletFontSize={this.props.tabletFontSize}
                      tableLineHeight={this.props.tableLineHeight}
                      animate={this.state.visible ? 'visible' : 'hidden'}
                      variants={animations}
                      margin={this.props.margin}
                      align={this.props.leftAligned ? 'left' : 'center'}
                      initial="hidden"
                    />
                  ) : (
                    <TitleUnderlinedContainer
                      underlineColor={this.props.underlineColor}
                      textColor={this.props.textColor}
                      fontSize={this.props.fontSize}
                      lineHeight={this.props.lineHeight}
                      dangerouslySetInnerHTML={convertHtml(
                        this.props.titleText
                      )}
                      mobileFontSize={this.props.mobileFontSize}
                      mobileLineHeight={this.props.mobileLineHeight}
                      tabletFontSize={this.props.tabletFontSize}
                      tableLineHeight={this.props.tableLineHeight}
                      animate={this.state.visible ? 'visible' : 'hidden'}
                      variants={animations}
                      margin={this.props.margin}
                      align={this.props.leftAligned ? 'left' : 'center'}
                      initial="hidden"
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      </VisibilitySensor>
    );
  }
}

export default TitleUnderlined;
